import axios from "axios";
import environment from "../environments/environment";
import { showNotification } from "../utils/customHooks/Toast";

// Create a new Axios instance with custom configuration
const Instance = axios.create({
  baseURL: environment.apiUrl,
  timeout: 1000 * 60 * 5,
  headers: {
    "Content-Type": "application/json",
    // Add any common headers (e.g., authentication tokens) here
  },
});

Instance.interceptors.response.use(
  (response) => {
    // console.log("response in interceptor : ", response);
    return response;
  },
  (error) => {
    console.log("Error in interceptor : ", error);
    if (error.response && error.response.status === 401) {
      if (error.response.data.error === "Invalid credentials") {
        //error came on login screen, so no need to refresh
        return;
      }
      localStorage.removeItem("accessToken");
      localStorage.removeItem("user");
      showNotification("Session Expired", "error");

      setTimeout(() => {
        window.location.href = `${environment.clientURL}/login`;
      }, 1000);
    }
    return Promise.reject(error);
  }
);

export default Instance;
