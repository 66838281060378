import React, { useEffect, useRef, useState } from "react";
import infoIcon from "../../assets/image/i-icon.svg";
import DoughnutChart from "../../pages/DoughnutChart";
import DownloadAsPDF from "../../pages/DownloadAsPDF";
import EmailPopup from "../../pages/Email/EmailPopup";
import InfoModal from "../../pages/InformationModal/InfoModal";
import LineChart from "../../pages/LineChart";
import Speedometer from "../../pages/Speedometer/Speedometer";
import { filterControlRiskIndicators } from "../../pages/TopRiskIndicators/filterControlRiskIndicators ";
import { filterInherentRiskIndicators } from "../../pages/TopRiskIndicators/filterInherentRiskIndicators";
import TreemapChart from "../../pages/TreemapChart";
import {
  averageAuRiskScores,
  getActiveAssessmentData,
  getRiskScores,
} from "../../services/ApiService";
import { showNotification } from "../../utils/customHooks/Toast";
import { useAssessmentunit } from "../Auth/ActiveAssessmentContext";
import { useAuth } from "../Auth/AuthContext";
import Layout from "../Common/Layout";

const Dashboard = () => {
  // Create a ref to store a reference to a DOM element
  const contentRef = useRef(null);
  const [selectedInterval, setSelectedInterval] = useState("Yearly");
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const { selectedUnit } = useAssessmentunit();
  const { user } = useAuth();

  const [chartValues, setChartValues] = useState(null);
  const [selectedData, setSelectedData] = useState([]);

  const [inherentRiskValues, setInherentRiskValues] = useState([]);
  const [controlRiskValues, setControlRiskValues] = useState([]);
  const [residualRiskValues, setResidualRiskValues] = useState([]);
  const [healthScore, setHealthScore] = useState(null);
  const [riskCategories, setRiskCategories] = useState([]);
  const [averageRiskScores, setAverageRiskScores] = useState(null);
  const [averageValue, setAverageValue] = useState(undefined);
  const [consumerBankingValue, setConsumerBankingValue] = useState(undefined);
  const [wholesaleBankingValue, setWholesaleBankingValue] = useState(undefined);
  const [defaultValue, setDefaultValue] = useState(undefined);

  useEffect(() => {
    if (selectedData) {
      setInherentRiskValues(selectedData[0]?.inherentRisk || []);
      setControlRiskValues(selectedData[1]?.controlRisk || []);
      setResidualRiskValues(selectedData[2]?.residualRisk || []);
    }
  }, [selectedData]);

  const handleSelectChange = (e) => {
    setSelectedInterval(e.target.value);
  };

  const handleShowModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => setShowModal(false);

  useEffect(() => {
    // Check if selectedUnit is present and exists in chartValues
    if (
      selectedUnit &&
      chartValues &&
      chartValues.hasOwnProperty(selectedUnit)
    ) {
      setSelectedData(chartValues[selectedUnit]);
      const defaultValue = Math.max(...chartValues.All[2].residualRisk);
      setDefaultValue(defaultValue);
      const consumerBankingValue = Math.max(
        ...chartValues["Consumer Banking"][2].residualRisk
      );
      setConsumerBankingValue(consumerBankingValue);
      const wholesaleBankingValue = Math.max(
        ...chartValues["Wholesale Banking"][2].residualRisk
      );
      setWholesaleBankingValue(wholesaleBankingValue);
      setAverageValue(
        (consumerBankingValue + wholesaleBankingValue + defaultValue) / 3
      );
    }
  }, [chartValues, selectedUnit]);

  useEffect(() => {
    if (selectedUnit) {
      getActiveAssessmentData()
        .then((res) => {
          setData(res.data);
        })
        .catch((error) => {
          console.log("Error while fetching active assessment data : ", error);
          showNotification("None of the assessment is Active", "error");
        });
    }
  }, [selectedUnit]);

  //Top 10 Inherent Risk Indicator
  const inherentRiskIndicators = filterInherentRiskIndicators(data);

  //Top 10 Control Risk Indicator
  const controlRiskIndicators = filterControlRiskIndicators(data);

  useEffect(() => {
    const fetchRiskScores = async () => {
      try {
        const response = await getRiskScores();
        console.log("response.data", response.data);
        setChartValues(response.data);
        setRiskCategories(response.data.RiskCategory);
      } catch (error) {
        console.log("Error while fetching risk scores : ", error);
        // showNotification(
        //   "Something went wrong while fetching Risk Scores",
        //   "error"
        // );
      }
    };

    const fetchAverageAuRiskScores = async () => {
      try {
        const response = await averageAuRiskScores();
        console.log("response.data for average AU Scores", response.data);

        setAverageRiskScores(response.data);
      } catch (error) {
        console.log("Error while fetching average AU risk scores : ", error);
      }
    };
    fetchAverageAuRiskScores();
    fetchRiskScores();
  }, []);

  return (
    <Layout>
      <div className="total-overview" ref={contentRef}>
        <div className="top-heading">
          <h2 className="heading-common exclude-from-pdf">
            Overview {user.role === "compliance-user" && `(${selectedUnit})`}
          </h2>
          <div className="top-icons-heading">
            <img
              src={infoIcon}
              className="exclude-from-pdf"
              alt="icon"
              onClick={handleShowModal}
              style={{ cursor: "pointer" }}
            />
            <DownloadAsPDF contentRef={contentRef} />
            <EmailPopup contentRef={contentRef} />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 d-flex">
            <div className="overall-health position-relative">
              <div className="part-of-cards">
                <h3>Overall health of iFCRA</h3>
              </div>
              <div className="needle-circle"></div>
              <div className="padding-box speedo-img pt-3">
                {averageRiskScores && (
                  <Speedometer
                    value={averageRiskScores[selectedUnit][2]["residualRisk"]}
                  />
                )}
              </div>
              <div className="d-flex justify-content-center mt-2 mb-2">
                <div className="fit-chart-bottom">
                  <p className="chart-customer-risk">
                    <span
                      className="box-chart-risk"
                      style={{ backgroundColor: "#42A121" }}
                    ></span>
                    Low
                  </p>
                  <p className="chart-customer-risk">
                    <span
                      className="box-chart-risk"
                      style={{ backgroundColor: "#F4B202" }}
                    ></span>
                    Medium
                  </p>
                  <p className="chart-customer-risk">
                    <span
                      className="box-chart-risk"
                      style={{ backgroundColor: "#DF4A11" }}
                    ></span>
                    High
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 d-flex">
            <div className="overall-health">
              <div className="d-flex justify-content-between">
                <div className="col-md-4 p-0">
                  <div className="part-of-cards border-right-label">
                    <h3>Inherent Risk</h3>
                  </div>
                  <div className="border-right-label doughnutchart">
                    <DoughnutChart
                      riskCategories={riskCategories}
                      values={inherentRiskValues}
                    />
                  </div>
                </div>
                <div className="col-md-4 p-0">
                  <div className="part-of-cards border-right-label ">
                    <h3>Control Risk</h3>
                  </div>
                  <div className="border-right-label doughnutchart">
                    <DoughnutChart
                      riskCategories={riskCategories}
                      values={controlRiskValues}
                    />
                  </div>
                </div>
                <div className="col-md-4 p-0">
                  <div className="part-of-cards">
                    <h3>Residual Risk</h3>
                  </div>
                  <div className="doughnutchart">
                    <DoughnutChart
                      riskCategories={riskCategories}
                      values={residualRiskValues}
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-center mb-2">
                <div className="fit-chart-bottom">
                  {riskCategories.includes("Customer Risk") && (
                    <p className="chart-customer-risk">
                      <span
                        className="box-chart-risk"
                        style={{ backgroundColor: "#0C2645" }}
                      ></span>
                      Customer Risk
                    </p>
                  )}
                  {riskCategories.includes("Geography Risk") && (
                    <p className="chart-customer-risk">
                      <span
                        className="box-chart-risk"
                        style={{ backgroundColor: "#33516A" }}
                      ></span>
                      Geography Risk
                    </p>
                  )}
                  {riskCategories.includes("Channel Risk") && (
                    <p className="chart-customer-risk">
                      <span
                        className="box-chart-risk"
                        style={{ backgroundColor: "#708597" }}
                      ></span>
                      Channel Risk
                    </p>
                  )}
                  {riskCategories.includes("Product Risk") && (
                    <p className="chart-customer-risk">
                      <span
                        className="box-chart-risk"
                        style={{ backgroundColor: "#BAC2DB" }}
                      ></span>
                      Product Risk
                    </p>
                  )}
                  {riskCategories.includes("TF PF Risk") && (
                    <p className="chart-customer-risk">
                      <span
                        className="box-chart-risk"
                        style={{ backgroundColor: "#E1E1E1" }}
                      ></span>
                      TF PF Risk
                    </p>
                  )}
                  {riskCategories.includes("Fraud Risk") && (
                    <p className="chart-customer-risk">
                      <span
                        className="box-chart-risk"
                        style={{ backgroundColor: "#00101c" }}
                      ></span>
                      Fraud Risk
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 d-flex">
            <div className="overall-health">
              <div className="part-of-cards">
                <h3>Trend Chart</h3>
              </div>
              <div className="padding-box">
                <div>
                  {" "}
                  <LineChart
                    selectedUnit={selectedUnit}
                    selectedInterval={selectedInterval}
                    handleSelectChange={handleSelectChange}
                  />{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 d-flex">
            <div className="overall-health">
              <div className="part-of-cards">
                <h3>Top 10 Inherent Risk Indicators</h3>
              </div>
              <div className="padding-box min-height-box">
                {inherentRiskIndicators?.length > 0 ? (
                  <TreemapChart data={inherentRiskIndicators} />
                ) : (
                  <h6 className="text-secondary my-5">
                    No data is available for preview
                  </h6>
                )}
              </div>
            </div>
          </div>
          <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 d-flex">
            <div className="overall-health">
              <div className="part-of-cards">
                <h3>Top 10 Control Risk Indicators</h3>
              </div>
              <div className="padding-box min-height-box">
                {controlRiskIndicators?.length > 0 ? (
                  <TreemapChart data={controlRiskIndicators} />
                ) : (
                  <h6 className="text-secondary my-5">
                    No data is available for preview
                  </h6>
                )}
              </div>
            </div>
          </div>
          <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 d-flex">
            <div className="overall-health">
              <div className="part-of-cards">
                <h3>Top 10 Residual Risk Indicators</h3>
              </div>
              <div className="padding-box min-height-box">
                {inherentRiskIndicators.length > 0 ? (
                  <TreemapChart data={inherentRiskIndicators} />
                ) : (
                  <h6 className="text-secondary my-5">
                    No data is available for preview
                  </h6>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <InfoModal
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        path="dashboard"
      />
    </Layout>
  );
};

export default Dashboard;
