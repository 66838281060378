import "font-awesome/css/font-awesome.min.css";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BookIcon from "../../assets/image/book-icon.svg";
import { useAuth } from "../../components/Auth/AuthContext";
import {
  updateAssessmentUnit,
  updateKRI,
  updateRiskCategory,
  updateSubCategory,
} from "../../services/ApiService";
import { showNotification } from "../../utils/customHooks/Toast";
import FileUpload from "../FileUpload";
import "./UpdateModal.css";

const UpdateModal = ({
  showModal,
  handleCloseModal,
  Data,
  modalType,
  fetchData,
}) => {
  const { user } = useAuth();

  // Initialize the state with default values
  const initialState = {
    overrideWeight: Data.overrideWeight,
    remark: Data.remark,
    status: Data.status,
  };

  const [inputError, setInputError] = useState(""); // State to hold the input error message
  const [isLoading, setIsLoading] = useState(false);
  const [selectedAction, setSelectedAction] = useState("");

  useEffect(() => {
    if (Data) {
      const initialState = {
        overrideWeight: Data.overrideWeight,
        remark: Data.remark,
        status: Data.status,
        newRemark: "",
      };
      setUpdatedData(initialState);
    }
  }, [Data, user.role]);
  const [updatedData, setUpdatedData] = useState(initialState);

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    const parsedValue = parseFloat(inputValue);

    if (isNaN(parsedValue) || parsedValue < 1 || parsedValue > 100) {
      setInputError("Override Weight must be between 1 and 100");
    } else {
      setInputError(""); // Clear the error message if input is valid
    }

    setUpdatedData({
      ...updatedData,
      [e.target.name]: inputValue,
    });
  };

  const handleTextareaChange = (name, value) => {
    const data = {
      ...updatedData,
      [name]: value,
    };
    setUpdatedData(data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const formData = new FormData();
    formData.append("overrideWeight", updatedData.overrideWeight);
    const mergedRemark = `${
      updatedData.remark ? `${updatedData.remark.replace(/:/g, ":\n")}\n` : ""
    }${user.name}: ${updatedData.newRemark},`;
    formData.append("remark", mergedRemark);

    if (selectedAction === "approved") {
      formData.append("status", "Approved");
    } else if (selectedAction === "rejected") {
      formData.append("status", "Rejected");
    } else {
      formData.append("status", "Pending");
    }

    // Define a function to handle the completion of the request
    const handleSuccess = (response) => {
      fetchData();
      handleCloseModal();
      setUpdatedData(initialState);
      showNotification(`Updated Successfully.`, "success");
    };

    // Define a function to handle the error of the request
    const handleError = (error) => {
      showNotification(
        `Failed to update data. Please try again later.`,
        "error"
      );
    };

    // Define a function to handle the completion of the request
    const handleRequestCompletion = () => {
      setIsLoading(false); // Set loading to false when the request is complete
    };

    // Based on modalType, different endpoints will be called to update data
    switch (modalType) {
      case "assessmentModal":
        updateAssessmentUnit(Data.id, formData)
          .then(handleSuccess)
          .catch(handleError)
          .finally(handleRequestCompletion);
        break;
      case "riskCategoryModal":
        updateRiskCategory(Data.id, formData)
          .then(handleSuccess)
          .catch(handleError)
          .finally(handleRequestCompletion);
        break;
      case "subCategoryModal":
        updateSubCategory(Data.id, formData)
          .then(handleSuccess)
          .catch(handleError)
          .finally(handleRequestCompletion);
        break;
      case "kriModal":
        updateKRI(Data.id, formData)
          .then(handleSuccess)
          .catch(handleError)
          .finally(handleRequestCompletion);
        break;
      default:
        break;
    }
  };

  const handleReset = () => {
    setUpdatedData({
      ...initialState,
      overrideWeight: 0, // Set overrideWeight to 0 when resetting
    });
  };
  return (
    <>
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>{Data.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="statistical-box">
              <p>Statistical Weight: {Data.weight}</p>
              <p className="override-field">
                Override Weight: &nbsp;{" "}
                <input
                  type="number"
                  placeholder="0"
                  name="overrideWeight"
                  required
                  value={updatedData.overrideWeight ?? updatedData.risk}
                  onChange={handleInputChange}
                  min={1}
                  max={100}
                />
              </p>
              <small className="text-danger" style={{ fontSize: "12px" }}>
                {inputError}
              </small>
            </div>
            <div>
              {updatedData.remark && (
                <>
                  <h7>Previous Remarks:</h7>
                  <p>
                    {updatedData.remark.split(",").map((item, index) => (
                      <React.Fragment key={index}>
                        {item}
                        {index !== updatedData.remark.split(",").length - 1 && (
                          <br />
                        )}
                      </React.Fragment>
                    ))}
                  </p>
                </>
              )}
            </div>

            <div className="Write-Justification">
              <div className="inner-body">
                <h3>
                  <img src={BookIcon} alt="icon" /> Write your Justification
                </h3>
                <textarea
                  value={updatedData.newRemark}
                  name="newRemark"
                  cols="96"
                  rows="5"
                  required
                  maxLength={300}
                  onChange={(e) =>
                    handleTextareaChange("newRemark", e.target.value)
                  }
                  placeholder="Enter your justification here..."
                />
              </div>
            </div>
            <div className="popup-uplod-btn">
              <FileUpload />
              {user.role !== "super-admin" && (
                <>
                  <button type="button" onClick={handleReset}>
                    Reset
                  </button>
                  <button
                    type="submit"
                    className={`save-btn-pop ${
                      updatedData.status === "Approved"
                        ? "disabled disabled-button"
                        : ""
                    }`}
                    onClick={() => setSelectedAction("Pending")}
                    disabled={isLoading || updatedData.status === "Approved"}
                  >
                    {!isLoading ? (
                      "Save"
                    ) : (
                      <i
                        className="fa fa-spinner fa-spin"
                        style={{ fontSize: 24 }}
                      />
                    )}
                  </button>
                </>
              )}
              {user.role === "super-admin" && (
                <>
                  <button
                    className={`reset-btn text-white ${
                      updatedData.status !== "Pending" &&
                      updatedData.status !== "Approved" &&
                      updatedData.status !== "Rejected"
                        ? "disabled disabled-button"
                        : " "
                    }`}
                    onClick={() => setSelectedAction("rejected")}
                    disabled={
                      updatedData.status !== "Pending" &&
                      updatedData.status !== "Approved" &&
                      updatedData.status !== "Rejected"
                    }
                  >
                    Reject
                  </button>

                  <button
                    type="submit"
                    className={`save-btn-pop ${
                      updatedData.status !== "Pending" &&
                      updatedData.status !== "Approved" &&
                      updatedData.status !== "Rejected"
                        ? "disabled disabled-button"
                        : " "
                    }`}
                    onClick={() => setSelectedAction("approved")}
                    disabled={
                      isLoading ||
                      (updatedData.status !== "Pending" &&
                        updatedData.status !== "Approved" &&
                        updatedData.status !== "Rejected")
                    }
                  >
                    {!isLoading ? (
                      "Approve"
                    ) : (
                      <i
                        className="fa fa-spinner fa-spin"
                        style={{ fontSize: 24 }}
                      />
                    )}
                  </button>
                </>
              )}
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default UpdateModal;
