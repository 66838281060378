// const env = process.env.NODE_ENV || "development";
const env = "development";

const environment = {
  local: {
    apiUrl: "http://10.10.10.101:8000",
    clientURL: "http://localhost:3000",
  },
  development: {
    // apiUrl: "http://ifcra-dev-api.solutionpot.com",
    apiUrl: "http://ifcra-dev.solutionpot.com:8000", //bypass apache url
    clientURL: "http://ifcra-dev.solutionpot.com",
  },
  production: {
    apiUrl: "https://solutionpot.com", //over the network
    clientURL: "https://ifcra.solutionpot.com",
  },
};

export default environment[env];
