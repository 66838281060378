import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import "../pages/DoughnutChart.css";

ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChart = ({ values, riskCategories }) => {
  const options = {
    responsive: true,
    maintainAspectRatio: true,
    layout: {
      padding: 30,
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: "Trend Chart",
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const value = data.datasets[0].data[context.dataIndex];
            return `${value}%`;
          },
        },
      },
    },
  };
  const bgColorData = [];
  if (riskCategories.includes("Customer Risk")) {
    bgColorData.push("#002645");
  }
  if (riskCategories.includes("Geography Risk")) {
    bgColorData.push("rgba(51, 81, 106, 1)");
  }
  if (riskCategories.includes("Channel Risk")) {
    bgColorData.push("rgba(112, 133, 151, 1)");
  }
  if (riskCategories.includes("Product Risk")) {
    bgColorData.push("rgba(0, 38, 69, 0.30)");
  }
  if (riskCategories.includes("TF PF Risk")) {
    bgColorData.push("#E1E1E1");
  }
  if (riskCategories.includes("Fraud Risk")) {
    bgColorData.push("#00101c");
  }

  const data = {
    // labels: [
    //   "Customer Risk",
    //   "Geography Risk",
    //   "Channel Risk",
    //   "Product Risk",
    //   "TF PF Risk",
    //   "Fraud Risk",
    // ],
    labels: riskCategories,
    datasets: [
      {
        data: values,
        // backgroundColor: [
        //   "#002645",
        //   "rgba(51, 81, 106, 1)",
        //   "rgba(112, 133, 151, 1)",
        //   "rgba(0, 38, 69, 0.30)",
        //   "#E1E1E1",
        //   "#00101c",
        // ],
        backgroundColor: bgColorData,
        cutout: "70%",
      },
    ],
  };

  const customDataTables = {
    id: "chart" + Math.random(),
    afterDatasetsDraw(chart) {
      const { ctx, data, width, height } = chart;

      ctx.save();
      const halfWidth = width / 2;
      const halfHeight = height / 2;

      let differenceSpace = 14;
      if (window.screen.width >= 1100 && window.screen.width < 1200) {
        differenceSpace = 10;
      } else if (window.screen.width >= 1200 && window.screen.width < 1300) {
        differenceSpace = 11;
      } else if (window.screen.width >= 1300 && window.screen.width < 1600) {
        differenceSpace = 12;
      } else if (window.screen.width >= 1600 && window.screen.width < 2000) {
        differenceSpace = 16;
      } else {
        differenceSpace = 14;
      }

      data.datasets[0].data.forEach((datapoint, index) => {
        const { x, y } = chart.getDatasetMeta(0).data[index].tooltipPosition();
        const xLine =
          x >= halfWidth ? x + differenceSpace : x - differenceSpace;
        const yLine =
          y >= halfHeight ? y + differenceSpace : y - differenceSpace;
        const extraLine = x >= halfWidth ? differenceSpace : -differenceSpace; // Adjust the spacing here

        ctx.font = "12px DM Sans";
        ctx.fillStyle = "#8A91A3";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle"; // Typo fixed here
        ctx.fillText(`${datapoint}%`, xLine + extraLine, yLine);

        // draw a line between slice and text
        // ctx.strokeStyle = "rgba(0, 38, 69, 1)";
        // ctx.beginPath();
        // ctx.moveTo(x, y);
        // ctx.lineTo(xLine, yLine);
        // ctx.lineTo(xLine + extraLine, yLine);
        // ctx.stroke();
      });

      ctx.restore();
    },
  };

  return (
    <Doughnut options={options} data={data} plugins={[customDataTables]} />
  );
};

export default DoughnutChart;
