import React, { useEffect, useRef, useState } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import bottomAarow from "../../assets/image/bottom-aarow.svg";
import Dot from "../../assets/image/dot.svg";
import infoIcon from "../../assets/image/i-icon.svg";
import AssessmentModal from "../../pages/AssessmentModal/AssessmentModal";
import DeleteModal from "../../pages/DeleteModal/DeleteModal";
import DownloadAsPDF from "../../pages/DownloadAsPDF";
import EmailPopup from "../../pages/Email/EmailPopup";
import InforModal from "../../pages/InformationModal/InfoModal";
import InfoTip from "../../pages/InfoTip";
import ShowOverrideWeight from "../../pages/ShowOverrideWeight";
import UpdateModal from "../../pages/UpdateModal/UpdateModal";
import {
  approveAllRequest,
  calculateWeight,
  callWeightRunner,
  deleteAssessmentUnit,
  deleteRiskCategory,
  getConfiguration,
  getConfigurationLogs,
  rejectAllRequest,
  requestReview,
  resetAll,
} from "../../services/ApiService";
import { isAllApproved } from "../../utils/customHooks/isAllApproved";
import { isPercentageValid } from "../../utils/customHooks/isPercentageValid";
import { showNotification } from "../../utils/customHooks/Toast";
import { useAuth } from "../Auth/AuthContext";
import { ConfigurationLogs } from "../Breadcrumb/GetLogs";
import Layout from "../Common/Layout";
import "./configuration.css";
const Configuration = () => {
  // Ref for a DOM element
  const contentRef = useRef(null);

  // States for managing selected options
  const [selectedRiskType, setRiskType] = useState(0);
  const [selectedAssessment, setAssessment] = useState(0);
  const [selectedCategory, setCategory] = useState(0);
  const [selectedSubCategory, setSubCategory] = useState(0);
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showInformationModal, setShowInformationModal] = useState(false);
  const [modaltype, setModalType] = useState("");
  const [modalData, setModalData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [configurationLogs, setConfigurationLogs] = useState([]);
  const [isPercentValid, setIsPercentValid] = useState(false);
  const [isReady, setIsReady] = useState(null);
  const [isReviewRequested, setIsReviewRequested] = useState(false);
  const [isRequested, setIsRequested] = useState(false);
  const [showCalcWeightModal, setShowCalcWeigthModal] = useState(false);

  const [loader1, setLoader1] = useState(false);
  const [loader2, setLoader2] = useState(false);
  const { user } = useAuth();

  // Function to handle change
  const handleTabChange = (id) => {
    setRiskType(id);
    setAssessment(0);
    setCategory(0);
    setSubCategory(0);
  };

  // Function to toggle assessment selection
  const toogleAssessment = (id) => {
    setAssessment(id);
    setCategory(0);
  };

  // Function to fetch data
  const fetchData = async () => {
    setIsLoading(true); // Set loading state to true
    try {
      //get configuration
      const response = await getConfiguration();
      setData(response.data);
      setIsReady(isAllApproved(response.data));
      setIsPercentValid(isPercentageValid(response.data));

      //get configuration logs
      const apiResponse = await getConfigurationLogs();
      setConfigurationLogs(apiResponse.data);
      setIsReviewRequested(
        apiResponse.data.some((val) => val.name === "Review Request")
      );
    } catch (error) {
      console.log("Error while loading the data : ", error);
      // showNotification("There was an issue loading the data.", "error");
    } finally {
      setIsLoading(false); // Set loading state to false when data is loaded
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Functions for showing the modal
  const handleShowModal = (modalType, Data) => {
    setShowModal(true);
    setModalType(modalType);
    setModalData(Data);
  };

  // Functions for hiding the modal
  const handleCloseModal = () => setShowModal(false);

  const handleShowInformationModal = () => {
    setShowInformationModal(true);
  };

  const handleCloseInformationModal = () => setShowInformationModal(false);

  //function to delete assessment-unit
  const handleDeleteAssessment = (unitId) => {
    setIsLoading(true); // Set isLoading to true before making the request

    deleteAssessmentUnit(unitId)
      .then((response) => {
        fetchData();
        showNotification("Assessment Deleted successfully", "success");
      })
      .catch((error) => {
        showNotification(`${error.response.data.error}`, "error");
      })
      .finally(() => {
        setIsLoading(false); // Set isLoading to false when the request is complete
      });
  };

  //function to delete risk-category
  const handleCategoryDelete = (categoryId) => {
    setIsLoading(true); // Set isLoading to true before making the request

    deleteRiskCategory(categoryId)
      .then((response) => {
        fetchData();
        showNotification("Category Deleted successfully", "success");
      })
      .catch((error) => {
        showNotification(`${error.response.data.error}`, "error");
      })
      .finally(() => {
        setIsLoading(false); // Set isLoading to false when the request is complete
      });
  };

  const handleApprove = () => {
    setLoader1(true);
    approveAllRequest()
      .then((response) => {
        fetchData();
        setLoader1(false);
        showNotification(`${response.data.message}`, "success");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleReject = () => {
    setLoader2(true);
    rejectAllRequest()
      .then((response) => {
        fetchData();
        setLoader2(false);
        showNotification(`${response.data.message}`, "success");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleResetAll = () => {
    setLoader2(true);
    resetAll()
      .then((response) => {
        fetchData();
        setLoader2(false);
        showNotification(`${response.data.message}`, "success");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const reviewRequest = () => {
    setLoader1(true);
    requestReview()
      .then((response) => {
        setLoader1(false);
        showNotification(`${response.data.message}`, "success");
        setIsRequested(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCalculateWeight = async () => {
    try {
      setIsLoading(true);
      await callWeightRunner();
      const response = await calculateWeight();

      showNotification(`${response.data.message}`, "success");
      fetchData();
      setIsLoading(false);
    } catch (error) {
      // setIsLoading(false);
      console.log("Error while calculating weight : ", error);
      showNotification(`${error?.response?.data?.error ?? error.message}`);
    }
  };

  return (
    <Layout>
      <div className="total-overview" ref={contentRef}>
        <div className="top-heading">
          <div>
            <h2 className="heading-common exclude-from-pdf">
              Configuration
              {!isPercentValid && (
                <OverlayTrigger
                  placement="bottom-end"
                  overlay={
                    <Tooltip id="tooltip-top">
                      Overall Percentage is not 100%
                    </Tooltip>
                  }
                >
                  <small className="fa fa-exclamation-triangle mx-2"></small>
                </OverlayTrigger>
              )}
            </h2>
            {/* breadcrumb */}
            <div className="col-12 exclude-from-pdf">
              <ConfigurationLogs data={configurationLogs} />
            </div>
          </div>

          <div className="d-flex justify-content-around align-items-center">
            <div className="button-configuration exclude-from-pdf mr-89">
              {user.role === "super-admin" ? (
                <InfoTip
                  show={isReady || !isReviewRequested}
                  info={`${isReady ? "No Changes Detected" : ""} ${
                    !isReviewRequested ? "Review is not yet requested" : ""
                  }`}
                >
                  <button
                    type="button"
                    className={`reset-btn ${
                      isReady || !isReviewRequested
                        ? "disabled disabled-button"
                        : ""
                    }`}
                    disabled={isReady || !isReviewRequested}
                    onClick={handleReject}
                  >
                    {!loader2 ? (
                      <React.Fragment>
                        <i className="fa fa-times-circle-o mx-1"></i>
                        Reject
                      </React.Fragment>
                    ) : (
                      <i
                        className="fa fa-spinner fa-spin"
                        style={{ fontSize: 24 }}
                      />
                    )}
                  </button>
                </InfoTip>
              ) : (
                <InfoTip show={isReady} info="No Changes Detected">
                  <button
                    type="button"
                    className={`reset-btn ${
                      isReady ? "disabled disabled-button" : ""
                    }`}
                    disabled={isReady}
                    onClick={handleResetAll}
                  >
                    {!loader2 ? (
                      <React.Fragment>
                        <i className="fa fa-times-circle-o mx-1"></i>
                        Reset
                      </React.Fragment>
                    ) : (
                      <i
                        className="fa fa-spinner fa-spin"
                        style={{ fontSize: 24 }}
                      />
                    )}
                  </button>
                </InfoTip>
              )}

              <button
                className="calculate-weights"
                onClick={() => handleCalculateWeight(data)}
              >
                Calculate Weights
              </button>

              {user.role === "super-admin" ? (
                <InfoTip
                  show={isReady || !isReviewRequested}
                  info={`${isReady ? "No Changes Detected" : ""} ${
                    !isReviewRequested ? "Review is not yet requested" : ""
                  }`}
                >
                  <button
                    type="button"
                    className={`save-btn 
                      ${
                        isReady || !isReviewRequested
                          ? "disabled disabled-button"
                          : ""
                      }`}
                    onClick={handleApprove}
                    disabled={isReady || !isReviewRequested}
                  >
                    {!loader1 ? (
                      <React.Fragment>
                        <i className="fa fa-check-circle-o mx-1"></i>
                        Approve
                      </React.Fragment>
                    ) : (
                      <i
                        className="fa fa-spinner fa-spin"
                        style={{ fontSize: 24 }}
                      />
                    )}
                  </button>
                </InfoTip>
              ) : (
                <InfoTip
                  show={isReady || !isPercentValid}
                  info={`${isReady ? "No Changes Detected" : ""} ${
                    !isPercentValid ? "Overall Percentage is not 100" : ""
                  }`}
                >
                  <button
                    type="button"
                    className="save-btn"
                    // className={`save-btn ${
                    //   isReady || !isPercentValid || isRequested === true
                    //     ? "disabled disabled-button"
                    //     : ""
                    // }`}
                    // disabled={isReady || !isPercentValid || isRequested}
                    onClick={reviewRequest}
                  >
                    {!loader1 ? (
                      <React.Fragment>
                        <i className="fa fa-check-circle-o mx-1"></i>
                        Save
                      </React.Fragment>
                    ) : (
                      <i
                        className="fa fa-spinner fa-spin"
                        style={{ fontSize: 24 }}
                      />
                    )}
                  </button>
                </InfoTip>
              )}
            </div>

            <div className="top-icons-heading">
              <img
                src={infoIcon}
                className="exclude-from-pdf"
                alt="icon"
                onClick={handleShowInformationModal}
                style={{ cursor: "pointer" }}
              />
              <DownloadAsPDF contentRef={contentRef} />
              <EmailPopup contentRef={contentRef} />
            </div>
          </div>
        </div>

        {/* Toggle Menu */}
        <ul className="nav nav-tabs common-tab" id="myTab" role="tablist">
          {data?.map((riskType, tabIndex) => (
            <li key={tabIndex}>
              <button
                className={`nav-link ${
                  tabIndex === selectedRiskType ? "active" : ""
                }`}
                id={`tab-${tabIndex}`}
                onClick={() => handleTabChange(tabIndex)}
                type="button"
                role="tab"
                aria-controls={`tab-pane-${tabIndex}`}
                aria-selected={tabIndex === selectedRiskType}
              >
                {riskType.name}
              </button>
            </li>
          ))}
        </ul>

        {/* Div that will display data on Toggle Menu */}
        {isLoading ? (
          <div className="spinner-container center-spinner">
            <div className="spinner">
              <div className="spinner-border text-secondary" role="status">
                <span className="sr-only"></span>
              </div>
            </div>
          </div>
        ) : (
          <div className="tab-content" id="myTabContent">
            {data?.map((riskType, tabIndex) => (
              <div
                key={`tab-pane-${tabIndex}`}
                className={`tab-pane accordion-item ${
                  tabIndex === selectedRiskType ? "show active" : ""
                }`}
                id={`tab-pane-${tabIndex}`}
                role="tabpanel"
                aria-labelledby={`tab-${tabIndex}`}
              >
                <div
                  id={`collapse-${tabIndex}`}
                  className={`accordion-collapse collapse ${
                    tabIndex === selectedRiskType
                      ? "show d-lg-block"
                      : "d-lg-block"
                  }`}
                  aria-labelledby={`heading-${tabIndex}`}
                  data-bs-parent="#myTabContent"
                >
                  <div className="accordion-body">
                    <table className="table accordion configuration-table">
                      {selectedAssessment === 0 ? (
                        <thead>
                          <tr>
                            <th scope="col" width="56">
                              Assessment Unit
                            </th>
                            <th scope="col" width="15">
                              Statistical Weights
                            </th>
                            <th scope="col" width="15">
                              {riskType.name !== "Control Risk"
                                ? "Override Weights"
                                : ""}
                            </th>
                            <th scope="col" width="7">
                              {riskType.name !== "Control Risk"
                                ? "Exclude"
                                : ""}
                            </th>
                            <th scope="col" width="7"></th>
                          </tr>
                        </thead>
                      ) : null}

                      <tbody>
                        {riskType.assessment_units?.map((unit) => (
                          <React.Fragment key={unit.id}>
                            <tr
                              className={
                                "parent-menu-item th-heading " +
                                (selectedAssessment !== unit.id &&
                                selectedAssessment !== 0
                                  ? "d-none"
                                  : "")
                              }
                            >
                              <td>{unit.name}</td>
                              <td>{unit.weight}%</td>
                              <td>
                                {riskType.name !== "Control Risk" ? (
                                  <Button
                                    className={"no-btn"}
                                    variant="text"
                                    onClick={() =>
                                      handleShowModal("assessmentModal", {
                                        ...unit,
                                        id: unit.id,
                                      })
                                    }
                                  >
                                    <ShowOverrideWeight
                                      overrideWeight={
                                        unit.weight === unit.overrideWeight
                                          ? "-"
                                          : unit.overrideWeight
                                      }
                                      status={unit.status}
                                    />
                                  </Button>
                                ) : null}
                              </td>
                              <td>
                                {riskType.name !== "Control Risk" && (
                                  <DeleteModal
                                    handleDelete={() =>
                                      handleDeleteAssessment(unit.id)
                                    }
                                    data={unit}
                                    isLoading={isLoading}
                                  />
                                )}
                              </td>
                              <td>
                                <Button
                                  className={
                                    "no-btn " +
                                    (selectedAssessment === unit.id
                                      ? "arrow-move"
                                      : "")
                                  }
                                  onClick={() =>
                                    toogleAssessment(
                                      selectedAssessment === unit.id
                                        ? 0
                                        : unit.id
                                    )
                                  }
                                  variant="text"
                                >
                                  <img src={bottomAarow} alt="icon" />
                                </Button>
                              </td>
                            </tr>

                            {selectedAssessment === unit.id ? (
                              // Conditionally render headings based on assessment unit name
                              <tr>
                                <th scope="col" width="56%">
                                  Risk Category
                                </th>
                                <th scope="col" width="15%">
                                  Statistical Weights
                                </th>
                                <th scope="col" width="15%">
                                  Override Weights
                                </th>
                                <th scope="col" width="7%">
                                  {riskType.name !== "Control Risk"
                                    ? "Exclude"
                                    : ""}
                                </th>
                                <th scope="col" width="7%"></th>
                              </tr>
                            ) : null}

                            {unit.risk_categories?.map((category) => (
                              <React.Fragment key={category.id}>
                                <tr
                                  className={
                                    "submenu-item th-heading collapse accordion-collapse bold-heading " +
                                    (selectedAssessment === unit.id
                                      ? "show"
                                      : "")
                                  }
                                >
                                  <td
                                    className={
                                      riskType.name === "Control Risk"
                                        ? "fw-bold"
                                        : ""
                                    }
                                  >
                                    {category.name}
                                  </td>
                                  <td
                                    className={
                                      riskType.name === "Control Risk"
                                        ? "fw-bold"
                                        : ""
                                    }
                                  >
                                    {category.weight}%
                                  </td>
                                  <td
                                    className={
                                      riskType.name === "Control Risk"
                                        ? "fw-bold"
                                        : ""
                                    }
                                  >
                                    <Button
                                      className={"no-btn"}
                                      variant="text"
                                      onClick={() =>
                                        handleShowModal("riskCategoryModal", {
                                          ...category,
                                          id: category.id,
                                        })
                                      }
                                    >
                                      <ShowOverrideWeight
                                        overrideWeight={
                                          category.weight ===
                                          category.overrideWeight
                                            ? "-"
                                            : category.overrideWeight
                                        }
                                        status={category.status}
                                      />
                                    </Button>
                                  </td>
                                  <td>
                                    {riskType.name !== "Control Risk" && (
                                      <DeleteModal
                                        handleDelete={() =>
                                          handleCategoryDelete(category.id)
                                        }
                                        data={category}
                                        isLoading={isLoading}
                                      />
                                    )}
                                  </td>
                                  <td>
                                    <Button
                                      className={
                                        "no-btn " +
                                        (selectedCategory === category.id
                                          ? "arrow-move"
                                          : "")
                                      }
                                      onClick={() =>
                                        setCategory(
                                          selectedCategory === category.id
                                            ? 0
                                            : category.id
                                        )
                                      }
                                      variant="text"
                                    >
                                      <img src={bottomAarow} alt="icon" />
                                    </Button>
                                  </td>
                                </tr>

                                {category.sub_categories?.map((subCategory) => (
                                  <React.Fragment key={subCategory.id}>
                                    <tr
                                      className={
                                        "submenu-item th-heading collapse accordion-collapse " +
                                        (selectedCategory === category.id
                                          ? "show"
                                          : "")
                                      }
                                    >
                                      <td>{subCategory.name}</td>
                                      <td>{subCategory.weight}%</td>
                                      <td>
                                        <Button
                                          className={"no-btn"}
                                          variant="text"
                                          onClick={() =>
                                            handleShowModal(
                                              "subCategoryModal",
                                              {
                                                ...subCategory,
                                                id: subCategory.id,
                                              }
                                            )
                                          }
                                        >
                                          <ShowOverrideWeight
                                            overrideWeight={
                                              subCategory.weight ===
                                              subCategory.overrideWeight
                                                ? "-"
                                                : subCategory.overrideWeight
                                            }
                                            status={subCategory.status}
                                          />
                                        </Button>
                                      </td>
                                      <td></td>
                                      <td>
                                        <Button
                                          className={
                                            "no-btn " +
                                            (selectedSubCategory ===
                                            subCategory.id
                                              ? "arrow-move"
                                              : "")
                                          }
                                          onClick={() =>
                                            setSubCategory(
                                              selectedSubCategory ===
                                                subCategory.id
                                                ? 0
                                                : subCategory.id
                                            )
                                          }
                                          variant="text"
                                        >
                                          <img src={bottomAarow} alt="icon" />
                                        </Button>
                                      </td>
                                    </tr>

                                    {subCategory.key_risk_indicators?.map(
                                      (kri, index) => (
                                        <React.Fragment key={kri.id}>
                                          <tr
                                            className={
                                              "collapse accordion-collapse " +
                                              (selectedSubCategory ===
                                              subCategory.id
                                                ? "show"
                                                : "")
                                            }
                                            data-bs-parent=".table"
                                          >
                                            <td>{kri.name}</td>
                                            <td>{kri.weight}</td>
                                            <td>
                                              <ShowOverrideWeight
                                                overrideWeight={
                                                  kri.weight ===
                                                  kri.overrideWeight
                                                    ? "-"
                                                    : kri.overrideWeight
                                                }
                                                status={kri.status}
                                              />
                                            </td>
                                            <td></td>
                                            <td>
                                              <Button
                                                className={"no-btn"}
                                                variant="text"
                                                onClick={() =>
                                                  handleShowModal("kriModal", {
                                                    ...kri,
                                                    id: kri.id,
                                                  })
                                                }
                                              >
                                                <img
                                                  src={Dot}
                                                  alt="Dot"
                                                  className="right-move-icon"
                                                />
                                              </Button>
                                            </td>
                                          </tr>
                                        </React.Fragment>
                                      )
                                    )}
                                  </React.Fragment>
                                ))}

                                {category.key_risk_indicators?.map((kri) => (
                                  <React.Fragment key={kri.id}>
                                    <tr
                                      className={
                                        "collapse accordion-collapse " +
                                        (selectedCategory === category.id
                                          ? "show"
                                          : "")
                                      }
                                      data-bs-parent=".table"
                                    >
                                      <td>{kri.name}</td>
                                      <td>{kri.weight}%</td>
                                      <td>
                                        <ShowOverrideWeight
                                          overrideWeight={
                                            kri.weight === kri.overrideWeight
                                              ? "-"
                                              : kri.overrideWeight
                                          }
                                          status={kri.status}
                                        />
                                      </td>
                                      <td></td>
                                      <td>
                                        <Button
                                          className={"no-btn"}
                                          variant="text"
                                          onClick={() =>
                                            handleShowModal("kriModal", {
                                              ...kri,
                                              id: kri.id,
                                            })
                                          }
                                        >
                                          <img
                                            src={Dot}
                                            alt="Dot"
                                            className="right-move-icon"
                                          />
                                        </Button>
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                ))}
                              </React.Fragment>
                            ))}
                          </React.Fragment>
                        ))}
                      </tbody>
                    </table>

                    {/* Modal for Creating New Assesment Unit */}
                    <div className="col-12 text-end">
                      {riskType.name === "Inherent Risk" && (
                        <AssessmentModal
                          riskType={riskType}
                          data={data}
                          fetchData={fetchData}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      {/* Modal for Updating */}
      <UpdateModal
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        Data={modalData}
        modalType={modaltype}
        fetchData={fetchData}
      />
      <InforModal
        showModal={showInformationModal}
        handleCloseModal={handleCloseInformationModal}
        path="configuration"
      />

      <ToastContainer />
    </Layout>
  );
};

export default Configuration;
